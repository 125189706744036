import React from "react";

class PrivacyPage extends React.Component {
  render() {
    return (
      <div id="scrollAllow">
        Privacy App
        <br />
        <br />
        Privacy Policy
        <br />
        Lucas Goldner built the app / website. This SERVICE is provided by
        <br />
        Lucas Goldner at no cost and is intended for use as is.
        <br />
        This page is used to inform visitors regarding my policies with the
        collection, use, and disclosure <br />
        of Personal Information if anyone decided to use my Service.
        <br />
        If you choose to use my Service, then you agree to the collection and
        use of information in <br />
        relation to this policy. The Personal Information that I collect is used
        for providing and improving <br />
        the Service. I will not use or share your information with anyone except
        as described in this <br />
        Privacy Policy.
        <br />
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, <br />
        which is accessible at app / website unless otherwise defined in this
        Privacy Policy.
        <br />
        <br />
        Information Collection and Use
        <br />
        For a better experience, while using our Service, I may require you to
        provide us with certain <br />
        personally identifiable information, including but not limited to Name,
        E-Mail, Location, Gender, <br />
        Phone number, Password, Birthday. The information that I request will be
        retained on your <br />
        device and is not collected by me in any way.
        <br />
        The app does use third party services that may collect information used
        to identify you.
        <br />
        Link to privacy policy of third party service providers used by the app
        <br />
        <br />
        <a
          className="lightBlueTextColor hoverLink"
          href="https://firebase.google.com/policies/analytics"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics for Firebase
        </a>
        <br />
        <br />
        <a
          className="lightBlueTextColor hoverLink"
          href="https://firebase.google.com/support/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firebase Crashlytics
        </a>
        <br />
        <br />
        <a
          className="lightBlueTextColor hoverLink"
          href="https://www.facebook.com/about/privacy/update/printable"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <br />
        <br />
        <br />
        Log Data
        <br />
        I want to inform you that whenever you use my Service, in a case of an
        error in the app I collect <br />
        data and information (through third party products) on your phone called
        Log Data. This Log <br />
        Data may include information such as your device Internet Protocol
        (“IP”) address, device <br />
        name, operating system version, the configuration of the app when
        utilizing my Service, the <br />
        time and date of your use of the Service, and other statistics.
        <br />
        <br />
        Cookies
        <br />
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique <br />
        identifiers. These are sent to your browser from the websites that you
        visit and are stored on <br />
        your device's internal memory.
        <br />
        This Service does not use these “cookies” explicitly. However, the app
        may use third party code <br />
        and libraries that use “cookies” to collect information and improve
        their services. You have <br />
        the option to either accept or refuse these cookies and know when a
        cookie is being sent to <br />
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions <br />
        of this Service.
        <br />
        <br />
        Service Providers
        <br />
        I may employ third-party companies and individuals due to the following
        reasons:
        <br />
        To facilitate our Service;
        <br />
        To provide the Service on our behalf;
        <br />
        To perform Service-related services; or
        <br />
        To assist us in analyzing how our Service is used.
        <br />
        I want to inform users of this Service that these third parties have
        access to your Personal <br />
        Information. The reason is to perform the tasks assigned to them on our
        behalf. However, they <br />
        are obligated not to disclose or use the information for any other
        purpose.
        <br />
        <br />
        Security
        <br />
        I value your trust in providing us your Personal Information, thus we
        are striving to use
        <br />
        commercially acceptable means of protecting it. But remember that no
        method of transmission <br />
        over the internet, or method of electronic storage is 100% secure and
        reliable, and I cannot <br />
        guarantee its absolute security.
        <br />
        <br />
        Links to Other Sites
        <br />
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed <br />
        to that site. Note that these external sites are not operated by me.
        Therefore, I strongly <br />
        advise you to review the Privacy Policy of these websites. I have no
        control over and assume no <br />
        responsibility for the content, privacy policies, or practices of any
        third-party sites or <br />
        services.
        <br />
        <br />
        Children’s Privacy
        <br />
        These Services do not address anyone under the age of 13. I do not
        knowingly collect personally <br />
        identifiable information from children under 13. In the case I discover
        that a child under 13 <br />
        has provided me with personal information, I immediately delete this
        from our servers. If you <br />
        are a parent or guardian and you are aware that your child has provided
        us with personal <br />
        information, please contact me so that I will be able to do necessary
        actions.
        <br />
        <br />
        Changes to This Privacy Policy
        <br />
        I may update our Privacy Policy from time to time. Thus, you are advised
        to review this page
        <br /> periodically for any changes. I will notify you of any changes by
        posting the new Privacy Policy on <br />
        this page.
        <br />
        This policy is effective as of 2021-01-21
        <br />
        <br />
        Contact Us
        <br />
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact me <br />
        at app / website.app@gmail.com.
        <br />
        <br />
        <br />
        Terms & Conditions
        <br />
        By downloading or using the app, these terms will automatically apply to
        you – you should make <br />
        sure therefore that you read them carefully before using the app. You’re
        not allowed to copy, <br />
        or modify the app, any part of the app, or our trademarks in any way.
        You’re not allowed to <br />
        attempt to extract the source code of the app, and you also shouldn’t
        try to translate the app into other languages, or make derivative
        versions. The app itself, and all the trade marks, <br />
        copyright, database rights and other intellectual property rights
        related to it, still belong to Lucas Goldner.
        <br />
        Lucas Goldner is committed to ensuring that the app is as useful and
        efficient as possible. For <br />
        that reason, we reserve the right to make changes to the app or to
        charge for its services, at <br />
        any time and for any reason. We will never charge you for the app or its
        services without <br />
        making it very clear to you exactly what you’re paying for.
        <br />
        The app / website app stores and processes personal data that you have
        provided to us, in order to <br />
        provide my Service. It’s your responsibility to keep your phone and
        access to the app <br />
        secure. We therefore recommend that you do not jailbreak or root your
        phone, which is the <br />
        process of removing software restrictions and limitations imposed by the
        official operating <br />
        system of your device. It could make your phone vulnerable to
        malware/viruses/malicious <br />
        programs, compromise your phone’s security features and it could mean
        that the app / website app <br />
        won’t work properly or at all.
        <br />
        The app does use third party services that declare their own Terms and
        Conditions.
        <br />
        Link to Terms and Conditions of third party service providers used by
        the app
        <br />
        <br />
        <a
          className="lightBlueTextColor hoverLink"
          href="https://firebase.google.com/terms/analytics"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics for Firebase
        </a>
        <br />
        <br />
        <a
          className="lightBlueTextColor hoverLink"
          href="https://firebase.google.com/terms/crashlytics"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firebase Chrashlytics
        </a>
        <br />
        <br />
        <a
          className="lightBlueTextColor hoverLink"
          href="https://www.facebook.com/legal/terms/plain_text_terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <br />
        <br />
        You should be aware that there are certain things that Lucas Goldner
        will not take responsibility <br />
        for. Certain functions of the app will require the app to have an active
        internet connection. <br />
        The connection can be Wi-Fi, or provided by your mobile network
        provider, but Lucas <br />
        Goldner cannot take responsibility for the app not working at full
        functionality if you don’t have <br />
        access to Wi-Fi, and you don’t have any of your data allowance left.
        <br />
        <br />
        If you’re using the app outside of an area with Wi-Fi, you should
        remember that your terms of the <br />
        agreement with your mobile network provider will still apply. As a
        result, you may be charged <br />
        by your mobile provider for the cost of data for the duration of the
        connection while <br />
        accessing the app, or other third party charges. In using the app,
        you’re accepting responsibility
        <br /> for any such charges, including roaming data charges if you use
        the app <br />
        outside of your home territory (i.e. region or country) without turning
        off data roaming. If you are <br />
        not the bill payer for the device on which you’re using the app, please
        be aware that we <br />
        assume that you have received permission from the bill payer for using
        the app.
        <br />
        <br />
        Along the same lines, Lucas Goldner cannot always take responsibility
        for the way you use the <br />
        app i.e. You need to make sure that your device stays charged – if it
        runs out of battery and <br />
        you can’t turn it on to avail the Service, Lucas Goldner cannot accept
        responsibility.
        <br />
        With respect to Lucas Goldner’s responsibility for your use of the app,
        when you’re using the app, <br />
        it’s important to bear in mind that although we endeavour to ensure that
        it is updated and <br />
        correct at all times, we do rely on third parties to provide information
        to us so that we can <br />
        make it available to you. Lucas Goldner accepts no liability for any
        loss, direct or indirect, <br />
        you experience as a result of relying wholly on this functionality of
        the app.
        <br />
        At some point, we may wish to update the app. The app is currently
        available on iOS – the <br />
        requirements for system(and for any additional systems we decide to
        extend the availability of <br />
        the app to) may change, and you’ll need to download the updates if you
        want to keep using <br />
        the app. Lucas Goldner does not promise that it will always update the
        app so that it is <br />
        relevant to you and/or works with the iOS version that you have
        installed on your device. <br />
        However, you promise to always accept updates to the application when
        offered to you, We may <br />
        also wish to stop providing the app, and may terminate use of it at any
        time without giving <br />
        notice of termination to you. Unless we tell you otherwise, upon any
        termination, (a) the <br />
        rights and licenses granted to you in these terms will end; (b) you must
        stop using the app, and <br />
        (if needed) delete it from your device.
        <br />
        <br />
        Changes to This Terms and Conditions
        <br />
        I may update our Terms and Conditions from time to time. Thus, you are
        advised to review this <br />
        page periodically for any changes. I will notify you of any changes by
        posting the new Terms <br />
        and Conditions on this page.
        <br />
        These terms and conditions are effective as of 2023-02-18
        <br />
        <br />
        Contact Us
        <br />
        If you have any questions or suggestions about my Terms and Conditions,
        do not hesitate to
        <br /> contact me at app / lucas.goldner@googlemail.com
      </div>
    );
  }
}

export default PrivacyPage;
